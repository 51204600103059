<template>
  <v-card class="elevation-1">
    <v-card-title>
      <span class="mt-4" v-if="formState!==null">{{formState}} Record</span>
      <v-btn
        icon
        small
        class="mt-4"
        text
        @click="formState='New'"
        title="Add New"
        v-else
      >
        <v-icon>{{icon.add}}</v-icon>
      </v-btn>
    </v-card-title>
    <v-card-text>
      <v-form>
        <v-autocomplete
          v-model="type"
          outlined
          dense
          clearable
          label="Type"
          clear
          :items="[
            { value: 'fixed', text: 'FIXED' },
            { value: 'flexi', text: 'FLEXIBLE' },
            { value: 'open', text: 'OPEN TIME' }
          ]"
          :class="formErrorFields.typeErrorMessage.length > 0 ? 'mb-3' : ''"
          :error-messages="formErrorFields.typeErrorMessage"
          :disabled="formState===null"
          @change="typeWasChange"
        ></v-autocomplete>

        <v-card elevation="0" class="pt-0">
          <v-card-text class="px-0 py-0">
            <v-subheader class="pl-0">Apply To</v-subheader>
            <v-radio-group
              v-model="applyTo"
              row
              class="pt-0 mt-0"
              :disabled="formState===null"
            >
              <v-radio
                label="All"
                value="all"
              ></v-radio>
              <v-radio
                label="Group"
                value="group"
              ></v-radio>
              <v-radio
                label="Employee"
                value="employee"
              ></v-radio>
            </v-radio-group>
            <v-autocomplete
              v-if="applyTo==='employee'"
              v-model="employee"
              outlined
              dense
              clearable
              multiple
              label="Employee"
              item-value="id"
              item-text="name"
              :search-input.sync="search"
              :loading="employeeLoading"
              :items="employees"
              :class="formErrorFields.employeeErrorMessage.length > 0 ? 'mb-3' : ''"
              :error-messages="formErrorFields.employeeErrorMessage"
              :disabled="formState===null"
              @change="formError().remove('employee', formErrorFields)"
            ></v-autocomplete>
            <v-autocomplete
              v-if="applyTo==='group'"
              v-model="group"
              outlined
              dense
              clearable
              label="Group"
              item-value="id"
              item-text="title"
              :items="groups"
              :loading="groupLoading"
              :class="formErrorFields.groupErrorMessage.length > 0 ? 'mb-3' : ''"
              :error-messages="formErrorFields.groupErrorMessage"
              :disabled="formState===null"
              @change="formError().remove('group', formErrorFields)"
            ></v-autocomplete>
            <v-autocomplete
              v-if="applyTo==='group'"
              v-model="except"
              outlined
              dense
              clearable
              multiple
              label="Except Employee"
              item-value="id"
              item-text="name"
              :loading="employeeLoading"
              :items="employees"
              :class="formErrorFields.exceptErrorMessage.length > 0 ? 'mb-3' : ''"
              :error-messages="formErrorFields.exceptErrorMessage"
              :disabled="formState===null"
              @change="formError().remove('except', formErrorFields)"
            ></v-autocomplete>
          </v-card-text>
        </v-card>

        <v-row class="mt-0 pt-0">
          <v-col cols="12" lg="6" class="pt-0 mt-0">
            <!-- From Date -->
            <v-dialog
              ref="dialog"
              v-model="modalFromDate"
              :return-value.sync="fromDateValue"
              persistent
              width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="fromDate"
                  label="From Date"
                  outlined
                  dense
                  placeholder="From Date"
                  v-bind="attrs"
                  v-on="on"
                  :append-icon="icon.calendar"
                  :class="formErrorFields.fromDateErrorMessage.length > 0 ? 'mb-3' : ''"
                  :error-messages="formErrorFields.fromDateErrorMessage"
                  :disabled="formState===null"
                  @click="formError().remove('fromDate', formErrorFields)"
                ></v-text-field>
              </template>
              <v-date-picker v-model="fromDate" scrollable>
                <v-btn text color="primary" @click="modalFromDate=false">
                  Cancel
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="modalFromDate=false">
                  OK
                </v-btn>
              </v-date-picker>
            </v-dialog>
          </v-col>
          <v-col cols="12" lg="6" class="pt-0 mt-0">
            <!-- To Date -->
            <v-dialog
              ref="dialog"
              v-model="modalToDate"
              :return-value.sync="toDateValue"
              persistent
              width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="toDate"
                  label="To Date"
                  outlined
                  dense
                  placeholder="To Date"
                  v-bind="attrs"
                  v-on="on"
                  :append-icon="icon.calendar"
                  :class="formErrorFields.toDateErrorMessage.length > 0 ? 'mb-3' : ''"
                  :error-messages="formErrorFields.toDateErrorMessage"
                  :disabled="formState===null"
                  @click="formError().remove('toDate', formErrorFields)"
                ></v-text-field>
              </template>
              <v-date-picker v-model="toDate" scrollable>
                <v-btn text color="primary" @click="modalToDate=false">
                  Cancel
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="modalToDate=false">
                  OK
                </v-btn>
              </v-date-picker>
            </v-dialog>
          </v-col>
        </v-row>
        <v-row v-if="!dayOff" class="mt-0 pt-0">
          <v-col cols="12" lg="6" class="pt-0 mt-0">
            <!-- In Time -->
            <v-dialog
              ref="dialog"
              v-model="modalTimeIn"
              :return-value.sync="timeInValue"
              persistent
              width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="timeIn"
                  label="Time In"
                  outlined
                  dense
                  placeholder="Time In"
                  v-bind="attrs"
                  v-on="on"
                  :append-icon="icon.clock"
                  :class="formErrorFields.timeInErrorMessage.length > 0 ? 'mb-3' : ''"
                  :error-messages="formErrorFields.timeInErrorMessage"
                  :disabled="formState===null"
                  @click="formError().remove('timeIn', formErrorFields)"
                ></v-text-field>
              </template>
              <v-time-picker v-model="timeIn" scrollable>
                <v-btn text color="primary" @click="modalTimeIn=false">
                  Cancel
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="modalTimeIn=false">
                  OK
                </v-btn>
              </v-time-picker>
            </v-dialog>
          </v-col>
          <v-col
            cols="12"
            lg="6"
            class="pt-0 mt-0"
          >
            <!-- Time Out -->
            <v-dialog
              ref="dialog"
              v-model="modalTimeOut"
              :return-value.sync="timeOutValue"
              persistent
              width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="timeOut"
                  label="Time Out"
                  outlined
                  dense
                  placeholder="Time Out"
                  v-bind="attrs"
                  v-on="on"
                  :append-icon="icon.clock"
                  :class="formErrorFields.timeOutErrorMessage.length > 0 ? 'mb-3' : ''"
                  :error-messages="formErrorFields.timeOutErrorMessage"
                  :disabled="formState===null"
                  @click="formError().remove('timeOut', formErrorFields)"
                ></v-text-field>
              </template>
              <v-time-picker v-model="timeOut" scrollable>
                <v-btn text color="primary" @click="modalTimeOut=false">
                  Cancel
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="modalTimeOut=false">
                  OK
                </v-btn>
              </v-time-picker>
            </v-dialog>
          </v-col>
        </v-row>

        <v-checkbox
          class="pt-0 mt-0"
          v-model="dayOff"
          label="Day Off"
          :disabled="formState===null"
        ></v-checkbox>

        <v-btn
          color="primary"
          :disabled="formState===null"
          :loading="loading"
          @click="submit">
          Submit
        </v-btn>
        <v-btn
          outlined
          class="mx-2"
          :disabled="formState===null"
          @click="reset"
        >
          Cancel
        </v-btn>
      </v-form>
    </v-card-text>

    <snackbar
      v-if="responseMessage.length > 0"
      :show="responseMessage.length > 0"
      :text="responseMessage"
      :color="responseMessageStatus"
      @close="responseMessage=''"
    ></snackbar>
  </v-card>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import { mdiCalendarOutline, mdiClockOutline, mdiPlusOutline } from '@mdi/js'
import crud from '@/composables/crud'
import get from '@/composables/get'
import snackbar from '@/components/SnackBar.vue'
import formError from '@/composables/formErrorHandler/formError'

// import SearchEmployee from '@/components/SearchEmployee.vue'

export default {
  props: ['data'],
  setup(props, { emit }) {
    const search = ref('')
    const openUploader = ref(false)
    const loading = ref(false)
    const message = ref('')
    const icon = {
      add: mdiPlusOutline,
      calendar: mdiCalendarOutline,
      clock: mdiClockOutline,
    }
    const fromDateValue = ref(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000).toISOString().substr(0, 10))
    const toDateValue = ref(new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000).toISOString().substr(0, 10))
    const timeInValue = ref(null)
    const timeOutValue = ref(null)
    const group = ref(null)
    const except = ref(null)
    const employee = ref(null)
    const employees = ref([])
    const employeeLoading = ref(false)
    const groups = ref([])
    const groupLoading = ref(false)
    const applyTo = ref('all')
    const type = ref('')
    const dayOff = ref(false)
    const fromDate = ref('')
    const toDate = ref('')
    const timeIn = ref('')
    const timeOut = ref('')
    const modalFromDate = ref(false)
    const modalToDate = ref(false)
    const modalTimeIn = ref(false)
    const modalTimeOut = ref(false)
    const formState = ref(null)
    const formErrorFields = ref({
      groupErrorMessage: '',
      employeeErrorMessage: '',
      exceptErrorMessage: '',
      timeInErrorMessage: '',
      timeOutErrorMessage: '',
      typeErrorMessage: '',
      fromDateErrorMessage: '',
      toDateErrorMessage: '',
      dayOffErrorMessage: '',
      applyToErrorMessage: '',
    })

    get(`${process.env.VUE_APP_URI}/api/payroll/scheduler/groups`, groups, groupLoading)

    watch(() => props.data, () => {
      group.value = props.data.applicable_to_group !== null ? props.data.applicable_to_group.split(',') : []
      group.value = group.value.map(val => parseInt(val, 10))
      employee.value = props.data.applicable_to_employee !== null ? props.data.applicable_to_employee.split(',') : []
      employee.value = employee.value.map(val => parseInt(val, 10))
      except.value = props.data.exceptTo
      type.value = props.data.type !== null ? props.data.type.toUpperCase() : ''
      applyTo.value = props.data.application_type
      fromDate.value = props.data.from
      toDate.value = props.data.to
      timeIn.value = props.data.timein
      timeOut.value = props.data.timeout
      dayOff.value = props.data.day_off === 'Y'
      formState.value = 'Edit'
    })

    watch(() => applyTo.value, val => {
      if (val === 'employee') {
        employees.value = []
        get(`${process.env.VUE_APP_URI}/api/payroll/employees/true`, employees, employeeLoading)
      }
    })

    watch(() => group.value, val => {
      if (val !== null && val.length) {
        employees.value = []
        get(`${process.env.VUE_APP_URI}/api/payroll/employees/group/${val}/true`, employees, employeeLoading)
      }
    })

    watch(() => employee.value, () => {
      search.value = ''
    })

    const {
      store,
      update,
      responseMessage,
      responseMessageStatus,
    } = crud()

    const reset = () => {
      formState.value = null
      group.value = null
      except.value = null
      employee.value = null
      type.value = null
      applyTo.value = 'all'
      dayOff.value = false
      fromDate.value = null
      toDate.value = null
      timeIn.value = null
      timeOut.value = null
    }

    const submit = async () => {
      const formData = {
        applyTo: applyTo.value,
        group: group.value,
        except: except.value,
        employee: employee.value,
        type: type.value.toLowerCase(),
        fromDate: fromDate.value,
        toDate: toDate.value,
        timeIn: timeIn.value,
        timeOut: timeOut.value,
        dayOff: dayOff.value ? 'Y' : 'N',
      }

      if (formState.value === 'New') {
        const uri = `${process.env.VUE_APP_URI}/api/payroll/scheduler`
        await store(uri, loading, formData, formErrorFields)
      } else {
        const uri = `${process.env.VUE_APP_URI}/api/payroll/scheduler/${props.data.id}`
        await update(uri, loading, formData, formErrorFields)
      }

      message.value = responseMessage.value
      if (responseMessageStatus.value === 'success') {
        emit('updateUniqueID')
      }
    }

    const typeWasChange = () => {
      formError().remove('type', formErrorFields.value)
      formError().remove('timeIn', formErrorFields.value)
      formError().remove('timeOut', formErrorFields.value)
    }

    return {
      applyTo,
      group,
      except,
      employee,
      employees,
      employeeLoading,
      groups,
      groupLoading,
      modalTimeIn,
      timeIn,
      timeInValue,
      modalTimeOut,
      timeOut,
      timeOutValue,
      type,
      modalFromDate,
      fromDate,
      fromDateValue,
      modalToDate,
      toDate,
      toDateValue,
      dayOff,
      icon,
      formState,
      reset,
      submit,
      loading,
      message,
      formErrorFields,
      formError,
      responseMessage,
      responseMessageStatus,
      openUploader,
      typeWasChange,
      search,
    }
  },

  components: {
    snackbar,

    // SearchEmployee,
  },
}
</script>
